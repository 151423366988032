import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import Vimeo from "@u-wave/react-vimeo"
import { use100vh } from "react-div-100vh"
import Cursor from "../images/EXIT.svg"
import WatchMe from "./svg/WatchMe"

export default function VideoModal({ videoThumbnail, videoLink }) {
  const height = use100vh()

  const [opened, setOpened] = useState(false)
  // const [paused, setPaused] = useState(false)

  const openHandler = () => {
    opened ? setOpened(false) : setOpened(true)
  }
  const [hovering, setHovering] = useState(false)
  const [left, setLeft] = useState(0)
  const [right, setRight] = useState(0)

  const mouseHandlerEnter = () => {
    setHovering(true)
  }
  const mouseHandlerLeave = () => {
    setHovering(false)
  }

  const positionHandler = e => {
    setLeft(e.clientX)
    setRight(e.clientY)
  }

  useEffect(() => {
    document.addEventListener("mousemove", positionHandler)
    return () => {
      document.removeEventListener("mousemove", positionHandler)
    }
  }, [])

  let greyScale = {
    backgroundColor: `rgba(25,25,25,0.6)`,
    cursor: `url(${Cursor}) 25 15, pointer`,
  }

  return (
    <>
      {hovering ? (
        <div
          className="fixed transform z-100 pointer-events-none "
          style={{ left: left + "px", top: right + "px" }}
        >
          <WatchMe />
        </div>
      ) : null}
      <div
        onClick={openHandler}
        onMouseEnter={mouseHandlerEnter}
        onMouseLeave={mouseHandlerLeave}
        className="video-thumbnail grid grid-cols-1 grid-rows-1"
      >
        {/* <div className="">
          <Img
            fluid={videoThumbnail.fluid}
            imgStyle={{
              objectFit: "contain",
              objectPosition: "center center",
            }}
            style={{ width: "100%" }}
          />
        </div> */}

        <div className="col-start-1 row-start-1 p-4 z-40 w-full flex flex-col justify-center items-center text-base lg:hidden">
          <h1 className="uppercase text-white">PLAY</h1>
        </div>
        <div className="z-30 col-start-1 row-start-1">
          <Img
            fluid={videoThumbnail.fluid}
            imgStyle={{
              objectFit: "contain",
              objectPosition: "center center",
            }}
            style={{ width: "100%" }}
            loading="eager"
          />
        </div>
      </div>
      <section
        style={greyScale}
        onClick={openHandler}
        className={
          (opened
            ? "visible transition-all duration-300 opacity-100"
            : "invisible transition-all duration-300 opacity-0") +
          " " +
          "absolute top-0 right-0 flex flex-col justify-center items-center z-50 h-screen w-screen transition-all duration-300 pb-44px"
        }
      >
        <div className="w-11/12 lg:w-9/12 grid grid-cols-1 grid-rows-1">
          <div className="col-start-1 row-start-1">
            <Vimeo
              video={videoLink}
              responsive
              showByline={false}
              // controls={false}
              showPortrait={false}
              showTitle={false}
            />
          </div>
          {/* <div className="col-start-1 row-start-1 z-100">
            <button className="text-white" onClick={playHandler}>
              PLAY
            </button>
          </div> */}
        </div>
      </section>
    </>
  )
}
