import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import RichText from "../components/RichText"
import { use100vh } from "react-div-100vh"
import Transition from "../components/Transition"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import ObjectInfiniteDiv from "../components/ObjectInfiniteDiv"
import Exit from "../components/svg/Exit"
import VideoModal from "../components/VideoModal"
import SEO from "../components/seo"

export default function Object({
  data: {
    contentfulObject: {
      date,
      description,
      featured,
      materials,
      objectName,
      gallery,
      path,
      year,
      videoLink,
      videoThumbnail,
    },
    allContentfulAbout,
  },
}) {
  const [infoOpen, setInfoOpen] = useState(false)
  const height = use100vh()
  const h95 = height ? height - 44 : "95vh"
  const breakpoints = useBreakpoint()

  const infoHandler = () => {
    infoOpen === false ? setInfoOpen(true) : setInfoOpen(false)
  }

  let video
  if (videoLink && videoThumbnail) {
    video = <VideoModal videoLink={videoLink} videoThumbnail={videoThumbnail} />
  }

  return (
    <Transition>
      <SEO title={`${objectName} | Ette`} link={`/${path}`} />
      <Layout about={allContentfulAbout}>
        <div
          className="grid grid-cols-1 lg:grid-cols-2 grid-rows-1"
          style={{ height: h95, maxHeight: h95 }}
        >
          {breakpoints.md ? (
            <section className="no-bar grid grid-cols-1 w-full h-full overflow-hidden">
              <ObjectInfiniteDiv
                video={video}
                content={gallery}
                column="mob-column"
              />
            </section>
          ) : (
            <section className="no-bar grid grid-cols-1 h-full overflow-hidden">
              <ObjectInfiniteDiv
                video={video}
                content={gallery}
                column="left-column"
              />
            </section>
          )}
          <div className="hidden lg:col-start-2 lg:flex flex-col justify-between p-4 ">
            <div className="flex flex-row">
              <div className="w-2/3">
                <h1 className="text-base uppercase">
                  {objectName}, {year}
                </h1>
                <h2 className="text-sm normal-case">{materials}</h2>
              </div>
              <a
                className="uppercase text-base"
                href={`mailto:info@etteobjects.com?subject=` + objectName}
              >
                Inquire
              </a>
            </div>
            <div className="text-lg">
              {description ? <RichText content={description.raw} /> : null}
            </div>
          </div>
        </div>
        <div className="z-40 fixed p-5 top-0 w-full lg:hidden">
          <div>
            {/* <h1 className="uppercase">
              {objectName}, {year}
            </h1>
            <h2 className="text-sm normal-case">{materials}</h2> */}
          </div>
        </div>
        <div
          style={{ height: height, maxHeight: height }}
          className={
            (infoOpen
              ? "transform translate-y-0"
              : "transform translate-y-full") +
            " " +
            "absolute no-bar overflow-y-scroll bg-grey bottom-0 right-0 p-5 pb-44px flex flex-col z-50 w-screen transition-all duration-300 lg:hidden"
          }
        >
          <div>
            <h1 className="uppercase">
              {objectName}, {year}
            </h1>
            <h2 className="text-xsm uppercase">{materials}</h2>
          </div>
          <div className="text-base uppercase pt-4">Inquire</div>
          <div className="text-lg pt-10 pb-5">
            {description ? <RichText content={description.raw} /> : null}
          </div>
        </div>
        {infoOpen ? (
          <button
            className="uppercase absolute top-0 right-0 z-50 pt-5 pr-5 lg:hidden"
            onClick={infoHandler}
          >
            <Exit />
          </button>
        ) : (
          <button
            className="uppercase absolute top-0 right-0 z-50 pt-5 pr-5 lg:hidden"
            onClick={infoHandler}
          >
            Info
          </button>
        )}
        <Link
          to="/overview"
          className="uppercase text-base hidden lg:block fixed top-0 left-0 p-4"
        >
          Back
        </Link>
      </Layout>
    </Transition>
  )
}

export const pageQuery = graphql`
  query projectBySlug($slug: String!) {
    contentfulObject(path: { eq: $slug }) {
      date
      description {
        raw
      }
      featured
      materials
      objectName
      path
      year
      videoLink
      videoThumbnail {
        fluid(quality: 50, maxWidth: 1200) {
          base64
          srcSet
          aspectRatio
        }
      }
      gallery {
        fluid(quality: 50, maxWidth: 1200) {
          base64
          srcSet
          aspectRatio
        }
      }
    }
    allContentfulAbout {
      edges {
        node {
          aboutDescription {
            raw
          }
          pressLinks {
            linkTitle
            link
          }
          socialMediaLinks {
            linkTitle
            link
          }
        }
      }
    }
  }
`
