import React, { useEffect, useState } from "react"
import Img from "gatsby-image"

export default function ObjectInfiniteDiv({ content, column, video }) {
  let columnDiv
  let origHeight
  const [addLast, setAddLast] = useState(false)

  useEffect(() => {
    columnDiv = document.getElementById(column)

    setTimeout(function () {
      if (content.length > 1) {
        origHeight = columnDiv.scrollHeight
        setAddLast(true)
      }
    }, 2000)
  }, [])

  const watchScroll = () => {
    let scrollWindowPos = Math.round(columnDiv.scrollTop)
    if (scrollWindowPos > origHeight) {
      columnDiv.scrollTop = 1
    }
    if (scrollWindowPos <= 0) {
      columnDiv.scrollTop = origHeight
    }
  }

  useEffect(() => {
    if (content.length > 1) {
      columnDiv.addEventListener("scroll", watchScroll)
    }

    return () => {
      if (content.length > 1) {
        columnDiv.removeEventListener("scroll", watchScroll)
      }
    }
  }, [columnDiv, origHeight])

  return (
    <div
      id={column}
      className="scroll-box no-bar flex flex-col overflow-y-scroll"
    >
      {content.map((img, i) => {
        return (
          <div className="w-full">
            <Img
              fluid={img.fluid}
              imgStyle={{ objectFit: "contain" }}
              style={{ width: "100%" }}
              key={i}
              loading="eager"
            />
          </div>
        )
      })}
      {video}
      {addLast ? (
        <>
          <div className="w-full">
            <Img
              fluid={content[0].fluid}
              imgStyle={{ objectFit: "contain" }}
              style={{ width: "100%" }}
              loading="eager"
            />
          </div>
          <div className="w-full">
            <Img
              fluid={content[1].fluid}
              imgStyle={{ objectFit: "contain" }}
              style={{ width: "100%" }}
              loading="eager"
            />
          </div>
        </>
      ) : null}
    </div>
  )
}
